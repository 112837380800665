import React, { createContext, useState, FC, useContext } from "react";

export const ModalContext = createContext<ModalContextType>(null);

export const useModal = () => useContext(ModalContext);

const ModalProvider: FC = ({ children }) => {
  const [song, setSong] = useState<Song>();
  const [shareUrl, setShareUrl] = useState<string>();
  const [shareTitle, setShareTitle] = useState<string>();
  const [shareMeta, setShareMeta] = useState<{
    [index: string]: any;
  }>();
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);
  const [isSkizaOpen, setIsSkizaOpen] = useState<boolean>(false);

  return (
    <ModalContext.Provider
      value={{
        skiza: {
          song,
          isOpen: isSkizaOpen,
          open: song => {
            setSong(song);
            setIsSkizaOpen(true);
          },
          close: () => {
            setSong(undefined);
            setIsSkizaOpen(false);
          }
        },
        share: {
          shareUrl,
          title: shareTitle,
          isOpen: isShareOpen,
          meta: shareMeta,
          open: (title, url, meta) => {
            setShareUrl(url);
            setShareTitle(title);
            setShareMeta(meta);
            setIsShareOpen(true);
          },
          close: () => {
            setShareUrl(undefined);
            setShareTitle(undefined);
            setShareMeta(undefined);
            setIsShareOpen(false);
          }
        }
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
