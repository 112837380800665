import React from "react";
import { useRouter } from "next/router";
import Link from "next/link";

export default function Navigation(props) {
  const router = useRouter();
  const isHome = ["/discover"].some(substring => router.asPath.includes(substring));
  const isLibrary = [
    "/songs",
    "/artists",
    "/sermons",
    "/videos",
    "/playlists",
    "/library",
    "/churches"
  ].some(substring => router.asPath.includes(substring));
  const isSearch = ["/search"].some(substring => router.asPath.includes(substring));
  const isMenu = ["/menu"].some(substring => router.asPath.includes(substring));

  return (
    <div className="flex items-center justify-between flex-grow-0 px-4 pt-2 pb-1.5">
      <Link href="/discover">
        <a href="" className="flex flex-col items-center">
          <img src={isHome ? "/home-active.png" : "/home-black.png"} alt="" className="w-5" />
          <p
            className={`mt-1 text-xs font-semibold leading-4 ${
              isHome ? "text-orange" : "text-gray-700"
            }`}
          >
            Home
          </p>
        </a>
      </Link>

      <Link href="/search">
        <a href="" className="flex flex-col items-center">
          <img src={isSearch ? "/search-active.png" : "/search-black.png"} alt="" className="w-4" />
          <p
            className={`mt-1 text-xs font-semibold leading-4 ${
              isSearch ? "text-orange" : "text-gray-700"
            }`}
          >
            Search
          </p>
        </a>
      </Link>

      <Link href="/library">
        <a href="" className="flex flex-col items-center">
          <img
            src={isLibrary ? "/library-active.png" : "/library-black.png"}
            alt=""
            className="w-4"
          />
          <p
            className={`mt-1 text-xs font-semibold leading-4 ${
              isLibrary ? "text-orange" : "text-gray-700"
            }`}
          >
            Library
          </p>
        </a>
      </Link>

      <button
        onClick={props.openNav}
        className="flex flex-col items-center outline-none focus:outline-none"
      >
        <img src={isMenu ? "/menu-active.png" : "/menu-black.png"} alt="" className="w-4" />
        <p
          className={`mt-1 text-xs font-semibold leading-4 ${
            isMenu ? "text-orange" : "text-gray-700"
          }`}
        >
          Menu
        </p>
      </button>
    </div>
  );
}
