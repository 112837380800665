import React, { useRef, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useAnalytics } from "../context/analytics-context";

import { useAudioPlayer } from "../context/audio-player-context";
import { useVideoPlayer } from "../context/video-player-context";
import { favouriteSong, likeSong } from "../lib/api";
import DualRingLoader from "./DualRingLoader";

function fmtTime(secs) {
  let s = Math.ceil(secs);
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
}

const AudioPlayer: React.FC = () => {
  const played = useRef([]);
  const mounted = useRef(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const { sendAnalytics } = useAnalytics();

  const {
    fullPlayer,
    song: _song,
    audioType,
    setCurrentSong,
    prevSong,
    nextSong,
    setIsPlaying,
    setSongs,
    setCurrentArtist,
    setCurrentPlaylist,
    setFullPlayer
  } = useAudioPlayer();

  const song = _song || ({} as Song);
  const { currentVideo } = useVideoPlayer();

  const [loading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [progress, setProgress] = useState(0);

  const [buffered, setBuffered] = useState(0);
  const [duration, setDuration] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [playerProgress, setPlayerProgress] = useState(0);

  const _sendAnalytics = () => {
    sendAnalytics({
      contentId: song.id,
      category: "Content",
      type: `${audioType}Played`,
      played: played.current
    });

    played.current = [];
  };

  useEffect(() => {
    mounted.current = true;

    return () => {
      if (played.current.length) {
        _sendAnalytics();
      }

      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.onloadedmetadata = () => {
        setDuration(audioElement.duration);
        setLoading(false);
      };

      audioElement.onloadstart = () => {
        setLoading(true);
      };

      audioElement.onprogress = () => {
        const duration = audioElement.duration;
        const bufferedLen = audioElement.buffered.length;

        if (duration > 0) {
          for (let i = 0; i < bufferedLen; i++) {
            if (audioElement.buffered.start(bufferedLen - 1 - i) < audioElement.currentTime) {
              const buffered = (audioElement.buffered.end(bufferedLen - 1 - i) / duration) * 100;
              if (mounted.current) setBuffered(buffered);
              break;
            }
          }
        }
      };

      audioElement.ontimeupdate = () => {
        const maxDuration = audioElement.duration;
        const currentTime = audioElement.currentTime;
        const currentProgress = Math.ceil((100 * currentTime) / maxDuration);
        const _progress = playing ? currentProgress + 1 : currentProgress;

        if (mounted.current) {
          setPlayerProgress(_progress);
          setCurrentTime(audioElement.currentTime);
        }

        const plays = [];
        const count = audioElement.played.length;

        Array.from(Array(count).keys()).map(index => {
          plays[index] = {
            start: audioElement.played.start(index),
            end: audioElement.played.end(index)
          };
        });

        if (plays.length && mounted.current) played.current = plays;
      };

      audioElement.onplaying = () => {
        isOpen && setPlaying(true);
      };

      audioElement.onpause = () => {
        isOpen && setPlaying(false);
      };

      audioElement.onended = () => {
        isOpen && setPlaying(false);
        _sendAnalytics();

        if (nextSong) {
          nextSong();
        } else {
          closePlayer();
        }
      };
    }
  }, [audioRef, isOpen, song.id, nextSong]);

  useEffect(() => {
    setIsPlaying(playing);
  }, [playing]);

  useEffect(() => {
    const audio = audioRef.current;
    setProgress(0);
    setPlaying(false);

    if (audio) {
      if (audio.canPlayType("audio/mpeg")) {
        audio.setAttribute("src", song.media?.url);
        // TODO songPlaying Analytics
      }
    }
  }, [song.id, audioRef]);

  useEffect(() => {
    const audio = audioRef.current;

    if (currentVideo?.id && audio) {
      if (!audio.paused) {
        audio.pause();
      }
    }
  }, [currentVideo, audioRef]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement && mounted) {
      audioElement.currentTime = progress || 0;
      if (mounted.current) setCurrentTime(progress);
    }
  }, [audioRef, progress, mounted]);

  const closePlayer = () => {
    setIsOpen(false);
    setFullPlayer(false);

    setTimeout(() => {
      // TODO songPlaying Analytics
      setSongs([], 0);
      setCurrentSong();
      setCurrentArtist();
      setIsPlaying(false);
      setCurrentPlaylist();
    }, 100);
  };

  const onPlayPause = () => {
    const audio = audioRef.current;

    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  };

  const likeMutation = useMutation(likeSong, {
    onSuccess: data => {
      if (data) {
        setCurrentSong({
          ...song,
          meta: {
            ...song.meta,
            like: true
          }
        });
      } else {
        // false
      }
    },
    onError: () => {
      // handle error
    }
  });

  const favouriteMutation = useMutation(favouriteSong, {
    onSuccess: data => {
      if (data) {
        setCurrentSong({
          ...song,
          meta: {
            ...song.meta,
            favorite: true
          }
        });
      } else {
        // false
      }
    },
    onError: () => {
      // handle error
    }
  });

  return (
    <div className={`px-3 pt-2 border-b border-gray-100 relative flex-1`}>
      {!fullPlayer && (
        <button
          type="button"
          onClick={() => {
            _sendAnalytics();
            closePlayer();
          }}
          className="absolute z-10 bg-white rounded-full shadow-lg -top-6 right-2 focus:outline-none active:bg-orange highlight-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      )}

      {fullPlayer ? (
        <div className="flex flex-col h-full">
          <div className="flex items-center py-2">
            <button
              onClick={() => setFullPlayer(false)}
              className="flex items-center space-x-2 highlight-none focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              <p className="font-bold ">Back</p>
            </button>

            <div className="flex-1"></div>

            <button className="flex items-center space-x-2 text-gray-700 highlight-none focus:outline-none">
              <p className="font-bold ">Share this</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z"
                />
              </svg>
            </button>
          </div>

          <div className="flex-shrink-0 mt-8 sm:mt-12">
            <div
              className="w-64 h-56 mx-auto bg-center bg-cover sm:w-72 sm:h-72 rounded-xl"
              style={{ backgroundImage: `url(${song.image?.url})` }}
            ></div>
          </div>

          <div className="flex flex-col justify-center flex-1">
            <p className="mt-10 text-xl font-bold">{song.title}</p>
            <p className="text-md">{song.author}</p>

            <div className="mt-10">
              <div className="relative flex flex-col justify-center w-full">
                <input
                  type="range"
                  className="absolute z-10 w-full h-[3px] bg-transparent cursor-pointer styled-slider slider-progress"
                  min="0"
                  max={duration}
                  onChange={evt => setProgress(parseFloat(evt.target.value))}
                  value={currentTime}
                  step="0.01"
                  autoComplete="off"
                />
                <div className="absolute rounded-full w-full h-[3px] bg-gray-200"></div>
                <div
                  style={{ width: buffered + "%" }}
                  className="absolute w-0 h-[3px] bg-orange bg-opacity-60"
                ></div>
                <div
                  style={{ width: playerProgress + "%" }}
                  className="absolute w-0 h-[3px] bg-orange"
                ></div>
              </div>

              <div className="flex items-center justify-between mt-1 font-bold text-gray-600">
                <span>{fmtTime(currentTime)}</span>
                <span>{fmtTime(duration)}</span>
              </div>

              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  onClick={() =>
                    likeMutation.mutate({
                      songId: song.id,
                      like: !song?.meta?.like || false
                    })
                  }
                  disabled={likeMutation.isLoading}
                  className={`flex items-center highlight-none focus:outline-none disabled:cursor-not-allowed disabled:text-gray-500 ${
                    song.meta.like ? "text-orange" : "text-gray-900"
                  } ${likeMutation.isLoading ? " animate-pulse" : ""}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-9 h-9 `}
                    fill={song.meta.like ? "rgba(252, 111, 37, 1)" : "none"}
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                </button>

                <button
                  onClick={() => {
                    _sendAnalytics();
                    prevSong();
                  }}
                  className="text-gray-800 highlight-none focus:outline-none"
                >
                  <svg
                    className="w-9 h-9"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M4 2v20h-2v-20h2zm18 0l-16 10 16 10v-20z" />
                  </svg>
                </button>

                <button
                  type="button"
                  onClick={onPlayPause}
                  className="flex-shrink-0 w-16 h-16 -mr-1 text-gray-800 bg-transparent rounded-full shadow-none focus:outline-none active:bg-orange highlight-none"
                >
                  {playing ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="block w-16 h-16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="block w-16 h-16"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </button>

                <button
                  onClick={() => {
                    _sendAnalytics();
                    nextSong();
                  }}
                  className="text-gray-800 highlight-none focus:outline-none"
                >
                  <svg
                    className="w-9 h-9"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 22v-20h2v20h-2zm-18 0l16-10-16-10v20z" />
                  </svg>
                </button>

                <button
                  type="button"
                  onClick={() =>
                    favouriteMutation.mutate({
                      songId: song.id,
                      status: !song?.meta?.favorite || false
                    })
                  }
                  disabled={favouriteMutation.isLoading}
                  className={`flex items-center highlight-none focus:outline-none disabled:cursor-not-allowed disabled:text-gray-500 ${
                    song.meta.favorite ? "text-orange" : "text-gray-900"
                  } ${favouriteMutation.isLoading ? " animate-pulse" : ""}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-9 h-9`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d={
                        song.meta.favorite
                          ? "M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          : "M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      }
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="relative flex flex-col justify-center w-full">
            <input
              type="range"
              className="absolute z-10 w-full h-[3px] bg-transparent cursor-pointer styled-slider slider-progress"
              min="0"
              max={duration}
              onChange={evt => setProgress(parseFloat(evt.target.value))}
              value={currentTime}
              step="0.01"
              autoComplete="off"
            />
            <div className="absolute rounded-full w-full h-[3px] bg-gray-200"></div>
            <div
              style={{ width: buffered + "%" }}
              className="absolute w-0 h-[3px] bg-orange bg-opacity-60"
            ></div>
            <div
              style={{ width: playerProgress + "%" }}
              className="absolute w-0 h-[3px] bg-orange"
            ></div>
          </div>

          <div className="flex items-center py-1 mt-2">
            <button
              onClick={() => setFullPlayer(true)}
              className="flex items-center flex-1 highlight-none"
            >
              <div
                className="flex-shrink-0 w-12 h-12 bg-cover rounded-lg"
                style={{ backgroundImage: `url(${song.image?.url})` }}
              />

              <div className="flex-1 px-3 text-justify">
                <p className="text-xs line-clamp-1">{song.title}</p>
                <p className="text-xxs line-clamp-1">{song.author}</p>
              </div>
            </button>

            {loading ? (
              <div className="w-10">
                <DualRingLoader size={30} />
              </div>
            ) : (
              <button
                type="button"
                onClick={onPlayPause}
                className="flex-shrink-0 w-12 h-12 -mr-1 text-gray-800 bg-transparent rounded-full shadow-none focus:outline-none active:bg-orange highlight-none"
              >
                {playing ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="block w-12 h-12"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="block w-12 h-12"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </>
      )}

      <div className="hidden" id="player-container">
        <audio autoPlay controls={false} preload="metadata" ref={audioRef}>
          <source src="" type="audio/mp3" />
        </audio>
      </div>
    </div>
  );
};

export default AudioPlayer;
