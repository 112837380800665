import React from "react";

type LoaderProps = {
  size: number;
};

export default function DualRingLoader(props: LoaderProps) {
  return (
    <div className="lds-dual-ring">
      <style jsx>{`
        .lds-dual-ring {
          display: inline-block;
          width: ${props.size}px;
          height: ${props.size}px;
        }
        .lds-dual-ring:after {
          content: " ";
          display: block;
          width: ${props.size}px;
          height: ${props.size}px;
          margin: 0px;
          border-radius: 50%;
          border: 3px solid #fc6f25;
          border-color: #fc6f25 transparent #fc6f25 transparent;
          animation: lds-dual-ring 1.2s linear infinite;
        }
        @keyframes lds-dual-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
}
