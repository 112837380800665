import { useEffect, useState } from "react";
import localForage from "localforage";
import { signOut } from "next-auth/client";
import axios from "axios";

import Navigation from "../components/Navigation";
import AudioPlayer from "../components/AudioPlayer";
import { SetSkizaModal, ShareModal } from "../components/modals";
import { useAudioPlayer } from "../context/audio-player-context";
import { useModal } from "../context/modal-context";
import useSession from "../hooks/use-auth-session";
import { isInStandaloneMode, isIos } from "../lib/utils";
import { CategoryType, EventType, useAnalytics } from "../context/analytics-context";
import AppSideDrawer from "../components/AppSideDrawer";
import SafariAddToHomeScreen from "../components/SafariAddToHomeScreen";
import DualRingLoader from "../components/DualRingLoader";

const base64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
};

export default function MainLayout(props) {
  const { share } = useModal();
  const [session] = useSession();
  const { sendAnalytics } = useAnalytics();
  const { song, fullPlayer } = useAudioPlayer();
  const [isOpen, openSideNav] = useState(false);

  const [registration, setRegistration] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [subscription, setSubscription] = useState<PushSubscription>(null);

  const [isInstalling, setIsInstalling] = useState(false);
  const [installBanner, setInstallBanner] = useState(true);
  const [showIosInstall, setShowIosInstall] = useState(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      window["workbox"] !== undefined
    ) {
      // run only in browser
      navigator.serviceWorker.ready.then(reg => {
        reg.pushManager.getSubscription().then(sub => {
          const expirationTime = (sub as any)?.expirationTime;
          if (sub && !(expirationTime && Date.now() > expirationTime - 5 * 60 * 1000)) {
            setSubscription(sub);
            setIsSubscribed(true);
          }
        });
        setRegistration(reg);
      });
    }
  }, []);

  const onSubscribe = async () => {
    setIsSubscribing(true);

    if (isSubscribed) {
      sendAnalytics({
        category: CategoryType.APP,
        type: EventType.ENABLE_NOTIFICATIONS
      });

      await subscription.unsubscribe();

      try {
        await axios.post("/api/notifications/unsubscribe", {
          userId: session?.user?.userId
        });
      } catch (error) {}

      setSubscription(null);
      setIsSubscribed(false);
    } else {
      sendAnalytics({
        category: CategoryType.APP,
        type: EventType.DISABLE_NOTIFICATIONS
      });

      const sub = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY)
      });

      try {
        await axios.post("/api/notifications/subscribe", {
          userId: session?.user?.userId,
          subscriptionInfo: sub.toJSON()
        });
      } catch (error) {}

      setSubscription(sub);
      setIsSubscribed(true);
    }
    setIsSubscribing(false);
  };

  const dismissIosInstallBanner = () => {
    setShowIosInstall(false);
  };

  const addToHomeScreen = () => {
    localForage.setItem("installed", undefined);

    if (isIos()) {
      setShowIosInstall(true);
      sendAnalytics({
        category: CategoryType.APP,
        type: EventType.IOS_SHOW_INSTALL
      });
    } else {
      setIsInstalling(true);
      // Show the modal add to home screen dialog
      (window as any).deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      (window as any).deferredPrompt.userChoice.then((choice: any) => {
        if (choice.outcome === "accepted") {
          // console.log("User accepted the A2HS prompt");
          setInstallBanner(false);
          sendAnalytics({
            category: CategoryType.APP,
            type: EventType.ACCEPT_INSTALL
          });
        } else {
          // console.log("User dismissed the A2HS prompt");
          sendAnalytics({
            category: CategoryType.APP,
            type: EventType.CANCEL_INSTALL
          });
        }
        // Clear the saved prompt since it can't be used again
        (window as any).deferredPrompt = null;
        setIsInstalling(false);
      });
    }
  };

  useEffect(() => {
    if (isInStandaloneMode()) {
      setInstallBanner(false);

      localForage.getItem("installed").then(installed => {
        if (installed) return;

        localForage.setItem("installed", true).then(() => {
          sendAnalytics({
            category: CategoryType.APP,
            type: EventType.APP_INSTALLED
          });
        });
      });
    }
  }, []);

  return (
    <>
      <div className="relative w-full max-w-xl mx-auto overflow-x-hidden">
        <div className={`flex w-full bg-gray-50 ${!!song ? "pb-28" : "pb-10"}`}>
          {props.children}
        </div>

        <div
          className={`fixed bottom-0 z-50 w-full max-w-xl mx-auto bg-white shadow-inner-0 ${
            fullPlayer && song
              ? "h-full flex flex-col overflow-y-auto overflow-x-hidden"
              : "border-t-2 border-gray-200"
          }`}
        >
          {song && <AudioPlayer />}
          <Navigation openNav={() => openSideNav(state => !state)} />
        </div>

        <div
          className={`top-0 left-0 w-full bg-gray-700 opacity-60 fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 ${
            isOpen ? "block" : "hidden"
          }`}
        ></div>

        <aside
          className={`transform top-0 left-0 w-72 p-3 max-w-xs bg-white fixed h-full overflow-auto ease-in-out transition-all duration-300 z-50 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex items-center">
            <button
              onClick={() => openSideNav(false)}
              className="flex items-center space-x-2 focus:outline-none focus:text-gray-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 -ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>

            <div className="flex items-center pb-1 ml-4">
              <p className="flex-1 text-lg font-bold text-gray-800">Back</p>
            </div>
          </div>

          <p className="py-4 text-2xl font-bold text-gray-800">Menu</p>

          {installBanner && (
            <button
              disabled={isInstalling}
              onClick={addToHomeScreen}
              className="w-full px-3 py-4 mt-4 bg-gray-200 rounded-md"
            >
              {isInstalling ? (
                <p className="flex items-center w-full">
                  <span className="flex-1">Adding...</span>
                  <DualRingLoader size={25} />
                </p>
              ) : (
                "Add Angaza to Homescreen"
              )}
            </button>
          )}

          <button
            onClick={onSubscribe}
            disabled={isSubscribing}
            className="w-full px-3 py-4 mt-4 bg-gray-200 rounded-md"
          >
            {isSubscribing ? (
              <p className="flex items-center w-full">
                <span className="flex-1">Processing...</span>
                <DualRingLoader size={25} />
              </p>
            ) : !isSubscribed ? (
              "Enable Push Notifications"
            ) : (
              "Disable Push Notifications"
            )}
          </button>
          {/* <button className="w-full px-3 py-4 mt-4 bg-gray-200 rounded-md">Account</button>
          <button className="w-full px-3 py-4 mt-4 bg-gray-200 rounded-md">About</button> */}
          <button
            onClick={() =>
              share.open(`Angaza Music`, window.location.host, { content: "Angaza PWA" })
            }
            className="w-full px-3 py-4 mt-4 bg-gray-200 rounded-md"
          >
            Share
          </button>
          <button
            onClick={() => {
              localForage.removeItem("token");
              localForage.removeItem("expiresAt");
              signOut({ callbackUrl: `${window.location.origin}/` });
            }}
            className="w-full px-3 py-4 mt-4 text-white bg-gray-800 rounded-md"
          >
            Logout
          </button>
        </aside>
      </div>

      <SetSkizaModal />
      <ShareModal />
      <AppSideDrawer
        isOpen={showIosInstall}
        side="bottom"
        useOverlay={true}
        onDismiss={dismissIosInstallBanner}
        className="ios-install-banner"
      >
        <SafariAddToHomeScreen onDismiss={dismissIosInstallBanner} />
      </AppSideDrawer>
    </>
  );
}
