import React, { useState } from "react";
import { useMutation } from "react-query";
import { useModal } from "../../context/modal-context";
import { setSkizaTune } from "../../lib/api";
import Modal from "./Modal";

export const SetSkizaModal = () => {
  const { skiza } = useModal();
  const [start, setStart] = useState(true);

  const setSkizaMutation = useMutation(setSkizaTune, {
    onMutate: () => {
      setStart(true);
    },
    onSettled: () => {
      setStart(false);
    }
  });

  const setSkiza = () => {
    setSkizaMutation.mutate({ skizaCode: skiza.song.skizzaCode });
  };

  return (
    <Modal isOpen={skiza.isOpen} onClose={skiza.close}>
      <div className="">
        {start && (
          <div className="">
            <p className="text-xl font-bold text-gray-700">Skiza Tune</p>
            <div className="flex items-center mt-4">
              <p className="flex-1 text-sm">
                Confirm setting <span className="italic font-bold">{skiza.song?.title}</span> by{" "}
                <span className="italic font-bold">{skiza.song?.author}</span> as your skiza tune
                for Ksh 1.50 daily.
              </p>

              <img
                src={skiza.song?.image.url}
                alt={skiza.song?.title}
                className="flex-shrink-0 w-16 h-16 border-none rounded-full"
              />
            </div>
            <div className="flex items-center mt-6 ">
              <button
                onClick={skiza.close}
                disabled={setSkizaMutation.isLoading}
                className="w-full mr-1.5 px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
              >
                CANCEL
              </button>

              <button
                onClick={setSkiza}
                disabled={setSkizaMutation.isLoading}
                className="w-full ml-1.5 px-4 py-2 text-sm font-medium text-white bg-green-500 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 disabled:bg-green-300 focus:ring-green-300"
              >
                {setSkizaMutation.isLoading ? "Processing..." : "OK"}
              </button>
            </div>
          </div>
        )}

        {setSkizaMutation.isSuccess && (
          <div className="py-3">
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
              <svg
                className="w-6 h-6 text-green-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </div>

            <div className="mt-6 text-center">
              <p className="text-sm text-gray-500">Skiza has been set successfully!</p>
            </div>

            <div className="items-center mt-6">
              <button
                onClick={skiza.close}
                className="w-full px-4 py-2 text-base font-medium text-white bg-green-500 rounded-md shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
              >
                OK
              </button>
            </div>
          </div>
        )}

        {setSkizaMutation.isError && (
          <div className="py-3">
            <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>

            <div className="mt-6 text-center">
              <p className="text-sm text-gray-500">Skiza was not set successfully!</p>
            </div>

            <div className="items-center mt-6">
              <button
                onClick={skiza.close}
                disabled={setSkizaMutation.isLoading}
                className="w-full mr-1.5 px-4 py-2.5 text-sm font-medium text-white bg-red-500 rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
              >
                CANCEL
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
