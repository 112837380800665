import React, { createContext, useState, FC, useContext, useEffect } from "react";

export const VideoPlayerContext = createContext<VideoPlayerContextType>(null);

export const useVideoPlayer = () => useContext(VideoPlayerContext);

const VideoPlayerProvider: FC = ({ children }) => {
  const [currentVideo, setCurrentVideo] = useState<Video | undefined>();

  return (
    <VideoPlayerContext.Provider
      value={{
        currentVideo,
        setCurrentVideo
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  );
};

export default VideoPlayerProvider;
