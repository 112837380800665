import React from "react";

type DrawerProps = {
  side?: string;
  isOpen: boolean;
  close?: boolean;
  useOverlay?: boolean;
  onDismiss?: () => void;
  className?: string;
};

const AppSideDrawer: React.FC<DrawerProps> = ({
  side = "left",
  isOpen,
  onDismiss,
  className,
  useOverlay = false,
  close = false,
  children
}) => {
  return (
    <>
      {useOverlay && isOpen && (
        <div onClick={onDismiss} className={`app-overlay ${isOpen ? "open" : ""}`} />
      )}

      <div className={`side-drawer-container ${side} ${className} ${isOpen ? "open" : ""}`}>
        {close && <button onClick={onDismiss}>Close</button>}
        {children}
      </div>

      <style jsx>{`
        .app-overlay {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          z-index: 999;
          opacity: 0;
          background: rgba(0, 0, 0, 0.3);
          transition: all 10s ease-out;
        }

        .app-overlay.open {
          opacity: 1;
        }

        .side-drawer-container {
          position: fixed;
          z-index: 9999;
          background: #fff;
          opacity: 0;
          transition: all 0.3s ease-out;
        }

        .side-drawer-container.open {
          opacity: 1;
        }

        .side-drawer-container.ios-install-banner {
          background-color: transparent;
          padding: 0 20px;
        }

        .side-drawer-container.left {
          top: 0;
          left: 0;
          height: 100%;
          width: 80%;
          max-width: 380px;
          transform: translateX(-100%);
        }

        .side-drawer-container.left.open {
          box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
          transform: translateX(0);
        }

        .side-drawer-container.right {
          top: 0;
          right: 0;
          height: 100%;
          width: 80%;
          max-width: 380px;
          transform: translateX(100%);
        }

        .side-drawer-container.right.open {
          transform: translateX(0);
        }

        .side-drawer-container.top {
          top: 0;
          left: 0;
          height: auto;
          width: 100%;
          max-height: 100%;
          transform: translateY(-100%);
        }

        .side-drawer-container.top.open {
          transform: translateY(0);
        }

        .side-drawer-container.bottom {
          bottom: 0;
          left: 0;
          height: auto;
          width: 100%;
          max-height: 100%;
          transform: translateY(100%);
        }

        .side-drawer-container.bottom.open {
          transform: translateY(0);
        }
      `}</style>
    </>
  );
};

export default AppSideDrawer;
