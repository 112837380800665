import { Session, User } from "next-auth";
import { useRouter } from "next/router";
import React, { createContext, useState, FC, useContext, useEffect } from "react";
import useSession from "../hooks/use-auth-session";
import { api } from "../lib/api";

type AuthContextType = {
  user: User;
  session: Session;
  loading: boolean;
};

export const AuthContext = createContext<AuthContextType>(null);

export const useAuth = () => useContext(AuthContext);

const AuthProvider: FC = ({ children }) => {
  const router = useRouter();
  const [redirectPath, setRedirectPath] = useState(router.asPath);

  useEffect(() => {
    if (router.pathname !== "/signin" && router.pathname !== "/") {
      setRedirectPath(`/signin?redirectTo=${router.asPath}`);
    }
  }, [router]);

  const [session, loading] = useSession({
    required: true,
    redirectTo: redirectPath,
    queryConfig: {
      staleTime: 60 * 60 * 3 * 1000, // 3 hours
      refetchInterval: 60 * 60 * 1000 // 60 minutes
    }
  });

  useEffect(() => {
    api.defaults.headers.common["x-auth-token"] = `${session?.accessToken}`;
  }, [session]);

  return (
    <AuthContext.Provider
      value={{
        session,
        loading,
        user: session?.user
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
