import React, { createContext, useState, FC, useContext, useEffect } from "react";

export const AudioPlayerContext = createContext<AudioPlayerContextType>(null);

export const useAudioPlayer = () => useContext(AudioPlayerContext);

const AudioPlayerProvider: FC = ({ children }) => {
  const [songs, _setSongs] = useState<Songs>([]);
  const [song, setSong] = useState<Song | undefined>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [fullPlayer, setFullPlayer] = useState(false);
  const [artist, _setArtist] = useState<Artist | undefined>();
  const [playlist, _setPlaylist] = useState<Playlist | undefined>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [audioType, setAudioType] = useState<AudioType>("song");

  const setCurrentSong = (song: Song, audioType: AudioType = "song") => {
    setSong(song);
    setAudioType(audioType);
  };
  const setCurrentPlaylist = (playlist?: Playlist, songs: Songs = []) => {
    _setSongs(songs);
    _setPlaylist(playlist);
  };

  const setCurrentArtist = (artist?: Artist, songs: Songs = []) => {
    _setSongs(songs);
    _setArtist(artist);
  };

  const setSongs = (songs: Songs, index: number) => {
    _setSongs(songs);
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (songs.length > 0) setCurrentSong(songs[0]);
  }, [songs]);

  useEffect(() => {
    if (songs.length > 0) setCurrentSong(songs[currentIndex]);
  }, [currentIndex]);

  const nextSong = () => {
    if (songs.length > 1 && currentIndex < songs.length - 1) {
      setCurrentIndex(current => current + 1);
    } else if (currentIndex == songs.length - 1) {
      setCurrentSong(undefined);
      setSongs([], 0);
    }
  };

  const prevSong = () => {
    if (songs.length > 1 && currentIndex > songs.length - 1)
      setCurrentIndex(current => current - 1);
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        song,
        setSongs,
        playlist,
        artist,
        nextSong,
        prevSong,
        isPlaying,
        audioType,
        setIsPlaying,
        setCurrentSong,
        setCurrentArtist,
        setCurrentPlaylist,
        fullPlayer,
        setFullPlayer: (full: boolean) => setFullPlayer(full)
      }}
    >
      {children}
    </AudioPlayerContext.Provider>
  );
};

export default AudioPlayerProvider;
