import React from "react";

import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { Provider as SessionProvider } from "next-auth/client";

import { QueryClient, QueryClientProvider } from "react-query";
import { Hydrate } from "react-query/hydration";
import { ReactQueryDevtools } from "react-query/devtools";

import "../styles/globals.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AudioPlayerProvider from "../context/audio-player-context";
import AuthProvider from "../context/auth-context";
import MainLayout from "../layouts/main-layout";
import VideoPlayerProvider from "../context/video-player-context";
import ModalProvider from "../context/modal-context";
import AnalyticsProvider from "../context/analytics-context";
import { isBrowser } from "../lib/utils";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const sessionOptions = {
  clientMaxAge: 60 * 60, // Re-fetch session if cache is older than 60 minutes
  keepAlive: 60 * 60
};

const MyApp: React.FC<AppPropsWithLayout> = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => <MainLayout>{page}</MainLayout>);

  const queryClientRef = React.useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          retry: true,
          staleTime: 60 * 1000,
          refetchOnWindowFocus: false
        }
      }
    });
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider session={pageProps.session} options={sessionOptions}>
          <ModalProvider>
            <AuthProvider>
              <AnalyticsProvider>
                <VideoPlayerProvider>
                  <AudioPlayerProvider>
                    {getLayout(
                      <div className="flex flex-col w-full min-h-full">
                        <Component {...pageProps} />
                      </div>
                    )}
                  </AudioPlayerProvider>
                </VideoPlayerProvider>
              </AnalyticsProvider>
            </AuthProvider>
          </ModalProvider>
        </SessionProvider>
      </Hydrate>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

if (isBrowser && "serviceWorker" in navigator) {
  window.addEventListener("beforeinstallprompt", ev => {
    // Prevent the mini-infobar from appearing on mobile
    ev.preventDefault();
    // Stash the event so it can be triggered later.
    (window as any).deferredPrompt = ev;
  });
}

export default MyApp;
