import React from "react";

type ComponentProps = {
  onDismiss: () => void;
};

const SafariAddToHomeScreen = (props: ComponentProps) => {
  return (
    <div className="add-to-homescreen">
      <div className="close-button">
        <button onClick={props.onDismiss} />
      </div>

      <div id="install">
        Install this web app on your iPhone for the best experience. It's easy, just tap{" "}
        <img className="share-icon" src="/images/safari-share.png" /> and then{" "}
        <span>Add to Home Screen</span>.
      </div>
      <div className="notch-arrow" />
      <div className="notch-border" />

      <style jsx>
        {`
          .add-to-homescreen {
            position: relative;
            z-index: 99999;
            background: #fff;
            border-radius: 5px;
            padding: 10px 15px;
            line-height: 1.5;
            font-size: 14px;
            margin-bottom: 25px;
            box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.15);
          }

          .add-to-homescreen .share-icon {
            width: 16px;
            padding-top: 0px;
            display: inline-block;
            vertical-align: middle;
          }

          .add-to-homescreen span {
            font-weight: bold;
          }

          .add-to-homescreen .notch-arrow {
            position: absolute;
            bottom: -18px;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #fff;
            z-index: 3;
            left: 50%;
            transform: translateX(-50%);
          }

          .add-to-homescreen .notch-border {
            position: absolute;
            bottom: -20px;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid rgba(0, 0, 0, 0.1);
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
          }

          .add-to-homescreen .close-button {
            position: absolute;
            right: -12px;
            top: -15px;
          }

          .add-to-homescreen .close-button button {
            border: none;
            outline: none;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-size: cover;
            background-image: url("/images/close-btn.svg");
          }
        `}
      </style>
    </div>
  );
};

export default SafariAddToHomeScreen;
