import React from "react";
import ReactDOM from "react-dom";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  closable?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  closable = false
}) =>
  isOpen
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="fixed inset-0 z-50 flex items-center justify-center w-full h-full p-6 overflow-y-auto bg-gray-600 bg-opacity-50">
            <div
              className="relative w-full max-w-sm p-4 mx-auto bg-white border rounded-md shadow-lg"
              aria-modal
              aria-hidden
              tabIndex={-1}
              role="dialog"
            >
              <div className="modal">
                {closable && (
                  <div className="modal-header">
                    <p className="modal-title">{title}</p>
                    <button
                      type="button"
                      className="modal-close-button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={onClose}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                )}

                <div className="modal-content">{children}</div>
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default Modal;
