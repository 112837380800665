import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";

import Modal from "./Modal";
import { useModal } from "../../context/modal-context";
import { CategoryType, EventType, useAnalytics } from "../../context/analytics-context";

export const ShareModal = () => {
  const { share } = useModal();
  const { sendAnalytics } = useAnalytics();

  const shareCallback = async (platform: string) => {
    sendAnalytics({
      category: CategoryType.APP,
      type: EventType.SHARE,
      platform,
      content: share.meta?.content,
      meta: {
        ...(share.meta || {})
      }
    });
  };

  return (
    <Modal isOpen={share.isOpen} onClose={share.close}>
      <div className="">
        <div className="relative flex justify-between">
          <p className="font-semibold">Share</p>
          <button onClick={share.close} className="absolute text-gray-700 -right-2 -top-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        <div className="flex items-center justify-between mt-5">
          <FacebookShareButton
            url={share.shareUrl}
            quote={share.title}
            hashtag="#AngazaMusic"
            beforeOnClick={async () => await shareCallback("facebook")}
            className=""
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <TwitterShareButton
            url={share.shareUrl}
            title={share.title}
            hashtags={["AngazaMusic"]}
            beforeOnClick={async () => await shareCallback("twitter")}
            className=""
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>

          <TelegramShareButton
            url={share.shareUrl}
            title={share.title}
            beforeOnClick={async () => await shareCallback("telegram")}
            className=""
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <WhatsappShareButton
            url={share.shareUrl}
            title={share.title}
            beforeOnClick={async () => await shareCallback("whatsapp")}
            separator=":: "
            className=""
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>

          <LinkedinShareButton
            url={share.shareUrl}
            source="AngazaMusic"
            beforeOnClick={async () => await shareCallback("linkedin")}
            className=""
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>

          <EmailShareButton
            url={share.shareUrl}
            subject={share.title}
            beforeOnClick={async () => await shareCallback("email")}
            body="body"
            className=""
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </div>
    </Modal>
  );
};
